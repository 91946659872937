:root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

* {
  box-sizing: content-box;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}

textarea {
  display: block;
  font-family: "MS　ゴシック";
  font-size: 0.7rem;
}
#app {
  margin: 0 auto;
  text-align: center;
  display: flex;
}
#notify {
  color: black;
}

@media screen and (max-width: 768px) {
  body {
    margin: 0;
    display: flex;
    place-items: center;
    width: 100%;
    min-height: 100vh;
  }

  #app {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-flow: column;
  }

  textarea {
    width: 100%;
  }
}

/*
#app > .side {
  //max-width: 300px;
  // flex-basis: 300px;
}

#app > .main {
  // max-width: 1280px;
}
*/

.output {
  min-width: 400px;
  min-height: 400px;
  background-color: #000;
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

img {
  vertical-align: top;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}
